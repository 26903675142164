::selection {
    background: #f5f5f5;
}

::-moz-selection {
    background: #f5f5f5;
}

img::selection {
    background: transparent;
}

img::-moz-selection {
    background: transparent;
}

select:-moz-focusring, option:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;

    &:active, &:focus {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
}

html, body {
    position: relative;
    height: 100%;
    min-height: 100%;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    position: relative;
    background-color: $secondary;
    background-image: linear-gradient(-221deg, #131F35 45%, #0B121F 100%);
    font-family: $sans-serif;
    color: #fff;
}

.vertical-align {
    position: absolute;
    display: table;
    width: 100%;
    height: 100%;

    .align-middle {
        display: table-cell;
        vertical-align: middle;
    }

    .align-bottom {
        display: table-cell;
        vertical-align: bottom;
    }
}
