footer {
    position: fixed;
    width: 100%;
    right: 0;
    bottom: 0;
    padding: 30px;
    background-color: rgba(darken($secondary, 2%), .5);
    color: $neutral;
    line-height: 1.8;
    animation-name: slideUp;
    animation-duration: 1.2s;
    animation-timing-function: ease;
}
