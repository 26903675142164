#page-homepage {
    padding-bottom: 100px;

    .welcome {
        h1 {
            margin-top: 45px;
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 8px;
            color: $neutral;

            @media (max-width: $screen-xs-max) {
                font-size: 14px;
            }
        }

        img {
            max-width: 300px;

            @media (max-width: $screen-xs-max) {
                width: 200px
            }
        }
    }

    .align-middle {
        animation-name: fadeIn;
        animation-duration: 3s;
    }
}
