// typograghy
$sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;

// theme colors
$primary: #34538F;
$secondary: darken($primary, 30%);
$neutral: #8A8B8A;

// social colors
$facebook: #305991;
$twitter: #25A8D2;
$pinterest: #F40024;
$instagram: #0174A2;

// media
$retina: "(-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi)";
$landscape: "(orientation: landscape)";
